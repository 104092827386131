<template lang="html">
  <div class="container pt-4">
    <div class="d-flex">
      <h3>Аккаунт {{ $store.state.account.first_name }} {{ $store.state.account.last_name }} {{ $store.state.account.username }}</h3>
    </div>
    {{ $store.state.account }}
  </div>
</template>

<script>

export default {
  name: 'Account',
  async mounted () {
    try {
      this.id = this.$route.params.id
      this.$store.commit('mergeStore', {account: {}})
      this.$socket.emit('account', {id: this.id})
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss">
.pagination{
  button.page-link{
    background: black;
    border: black;
  }
}
</style>

<style scoped>
>>> {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: black;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
}
</style>
